<template>
  <div id="">
    <top-tab
      :tapList="tapList"
      @thisTabClick="thisTabClick"
      :thisTab="thisTab"
      :searchBoo="false"
    >
    </top-tab>

    <no-data v-if="userData.length == 0"></no-data>

    <van-list
      v-else
      error-text="请求失败，点击重新加载"
      v-model="loading"
      :finished="finished"
      finished-text="已无更多明细"
      @load="onLoad"
    >
      <div class="team_ul">
        <div class="team_li flex-container" v-for="item in userData">
          <img :src="item.member_avatar" class="tx" />
          <div class="middel flex1">
            <div class="flex-container">
              <h3>{{ item.member_nickname }}</h3>
              <img :src="returnGradeImg(item.inviter_level)" />
            </div>
            <span class="regTime">注册时间：{{ item.member_addtime }}</span>
            <span class="lastLogin"
              >最后登录：{{ item.member_logintime || "很久没有登录了~" }}</span
            >
          </div>
          <img src="@image/me/icon_new.png" class="new" v-if="time_type == 2" />
        </div>
        <!-- <div class="team_li flex-container">
          <img src="@image/me/tx.png" class='tx'>
          <div class="middel flex1">
            <div class="flex-container">
              <h3>这里是会员名</h3>
              <img src="@image/me/grade_1.png" >
            </div>
            <span class='regTime'>注册时间：2020-08-28 11:29</span>
            <span class='lastLogin'>最后登录：一周内</span>
          </div>
          <img src="@image/me/icon_new.png" class="new">
      </div>  -->
      </div>
    </van-list>
  </div>
</template>
<script>
import { List } from "vant";

import topTab from "@/components/topTab/";
// import topTabs from '../../common/topTabs.vue';
import noData from "@/components/noData/";
import { post } from "@get/http";
export default {
  components: {
    topTab,
    noData,
    vanList: List,
  },
  data: () => ({
    thisTab: "all",
    loading: false,
    finished: false,
    page: 1,
    per_page: 10,
    time_type: "1",
    userData: [],
    totalData: {},
    tapList: [
      {
        title: "全部",
        type: "all",
        state_type: "1",
      },
      {
        title: "本日",
        type: "pay",
        state_type: "2",
      },
      {
        title: "本月",
        type: "get",
        state_type: "3",
      },
      {
        title: "季度",
        type: "review",
        state_type: "4",
      },
    ],
  }),
  created() {
    //do something after creating vue instance
    this.onLoad();
  },
  methods: {
    thisTabClick(type) {
      this.time_type = type;
      this.page = 1;
      this.loading = false;
      this.finished = false;
      this.userData = [];
      this.onLoad();
    },
    changTitle() {
      this.tapList[0].title = `全部(${this.totalData.all})`;
      this.tapList[1].title = `本日(${this.totalData.today})`;
      this.tapList[2].title = `本月(${this.totalData.thisMonth})`;
      this.tapList[3].title = `季度(${this.totalData.thisThreeMonth})`;
    },
    onLoad() {
      this.loading = true;
      post("/v2.Memberinviter/user", {
        data: {
          page: this.page,
          per_page: this.per_page,
          time_type: this.time_type, //1  全部  2 今天  3  本月  4 本季度
        },
      }).then((result) => {
        this.totalData = result.count;
        this.changTitle();
        this.userData.push(...result.data);
        this.page++;
        if (result.data.length < this.per_page) {
          this.loading = true;
          this.finished = true;
        } else {
          this.loading = false;
        }
      });
    },
    returnGradeImg(val) {
      if (val == 0) {
        return "https://shop.19jiang.cn/h5/source/img/shop/grade_1.png";
      } else if (val == 1) {
        return "https://shop.19jiang.cn/h5/source/img/shop/grade_2.png";
      } else if (val == 2) {
        return "https://shop.19jiang.cn/h5/source/img/shop/grade_3.png";
      } else if (val == 3) {
        return "https://shop.19jiang.cn/h5/source/img/shop/grade_4.png";
      } else if (val == 4) {
        return "https://shop.19jiang.cn/h5/source/img/shop/grade_5.png";
      } else if (val == 5) {
        return "https://shop.19jiang.cn/h5/source/img/shop/grade_6.png";
      } else if (val == 6) {
        return "https://shop.19jiang.cn/h5/source/img/shop/grade_7.png";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@css/mixin.scss";

.team_ul {
  width: rem(710);
  margin: rem(20) auto;
  background: #ffffff;
  border-radius: rem(20);
  padding: 0 rem(32);
  box-sizing: border-box;
  .team_li {
    padding: rem(32) 0;
    // justify-content: center;
    align-items: center;
    border-bottom: rem(1) solid #ebebeb;
    &:last-of-type {
      border-bottom: none;
    }
    .tx {
      width: rem(116);
      height: rem(116);
      border-radius: 50%;
      display: block;
    }
    .middel {
      align-items: center;
      // height: rem(43);
      margin-left: rem(20);
      margin-top: rem(10);
      h3 {
        font-size: rem(28);
        color: #333333;
        font-weight: bold;
        height: rem(43);
        line-height: rem(50);
        max-width: rem(300);
        @include ell;
      }
      img {
        height: rem(43);
        display: block;
      }
    }
    .regTime,
    .lastLogin {
      display: block;
      font-size: rem(22);
      color: #808080;
      margin-top: rem(8);
    }
    .lastLogin {
      margin-top: rem(4);
    }
    .new {
      width: rem(76);
      height: rem(36);
      background: #ff4747;
      border-radius: rem(18);
    }
  }
}

::v-deep .van-list__finished-text,
::v-deep .van-list__placeholder {
  font-size: rem(28) !important;
}
</style>
